import React from "react";
import ipvImg from "../images/projects/ipv.jpg";
import bdImg from "../images/projects/bdmyshopi.jpg";
import biocheckImg from "../images/projects/biocheck.jpg";
import zorgbandImg from "../images/projects/zorgband.jpg";
import woonhavenImg from "../images/projects/woonhaven.jpg";
import entityonelogoImg from "../images/projects/entityonelogo.gif";
import projectflowImg from "../images/projects/projectflow.jpg";
import '../styles/components/projectspage.scss';
import { Link } from "gatsby";
import {motion} from "framer-motion";
import Blocktitle from "./Blocktitle";
import Bodytext from "./Bodytext";

const Project = props => (
    <div className="project--wrapper">
      <Link to={props.projectlink} className="project--img">
            <div className="project--img--inner">
              <motion.img whileHover={{ scale: 1.03 }} transition={{ ease: "easeInOut" }} data-src={props.projectimg} alt={props.projectname} className="lazyload"/>
            </div>
      </Link>
      <div className="project--description">
        <Blocktitle blockTitle={props.projectname} />
        <Bodytext bodyText={props.excerpt} />
        <motion.a whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} href={props.projectlink} className="project--btn">Read more</motion.a>
      </div>
    </div>
)


export default () => (
    <div className="projects--page">
      <Project
          projectlink="/projects/ipvdiergeneeskunde"
          projectname="IPV Diergeneeskunde"
          projectimg={ipvImg}
          excerpt="IPV is a Drupal commerce website where soon to be veterinarians and established veterinarians are able to buy supplementary courses to further advance their education."
      />
      <Project
          projectlink="/projects/bdmyshopi"
          projectname="BD MyShopi"
          projectimg={bdImg}
          excerpt="For this potential EntityOne customer I designed a website with reusable building blocks."
      />
      <Project
          projectlink="/projects/biocheck"
          projectname="Biocheck.UGent"
          projectimg={biocheckImg}
          excerpt="With EntityOne we created a web tool for the UGent faculty of veterinary medicine."
      />
      <Project
          projectlink="/projects/zorgband"
          projectname="Zorgband"
          projectimg={zorgbandImg}
          excerpt="For care organization Zorgband EntityOne developed a new corporate identity and a simple user friendly Drupal 8 content website."
      />
      <Project
          projectlink="/projects/woonhaven"
          projectname="Woonhaven"
          projectimg={woonhavenImg}
          excerpt="For this prospective EntityOne customer I designed a website after carefully studying the user experience by means of wireframing and analysing concerns they had with their current website."
      />
      <Project
          projectlink="/projects/logoanimation"
          projectname="EntityOne logo animation"
          projectimg={entityonelogoImg}
          excerpt="A logo animation for EntityOne's new and improved website."
      />
      <Project
          projectlink="/projects/projectworkflow"
          projectname="EntityOne Project workflow posters"
          projectimg={projectflowImg}
          excerpt="A set of infographic posters to explain EntityOne's workflow."
      />
    </div>
)