import React, { Component } from 'react';
import Pagetitle from "../components/Pagetitle";
import Header from "../components/Header";
import ProjectteaserPage from "../components/ProjectteaserPage";
import Footer from "../components/Footer";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Explore my projects!</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page">
          <div className="page projects">
            <Header />
            <div className="page--description--wrapper container">
              <Pagetitle pageTitle="Explore my projects!" />
            </div>
            <ProjectteaserPage />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;